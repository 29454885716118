<template>
    <v-layout row wrap class="store-single-wrapper">
        <v-flex md3>
            <store-filter></store-filter>
        </v-flex>
        <v-flex xs9>
            <store-products :products="products" :user="user"></store-products>
            <div class="text-center" v-show="products.length">
                <v-pagination v-model="page"
                              circle
                              :total-visible="7"
                              :length="max_count"></v-pagination>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>
    import StoreFilter from '../../components/store/StoreFilter'
    import StoreProducts from '../../components/store/Products'

    export default {
        components: {
            StoreFilter,
            StoreProducts
        },
        name: "FactoryProductsPage",
        data: () => ({
            title: "Factory Products",
            factory_id:0,
            products:[],
            user: {}

        }),
        watch: {
            '$route.params.factory_id': function (factory_id) {
                this.load_store_products()
            },
        },
        created() {
            this.user = JSON.parse(localStorage.getItem('user'));
            this.load_factory_products()
        },
        methods: {
            load_factory_products()
            {
                this.factory_id = this.$route.params.factory_id;
                console.log("loading");
                this.$http.get(this.$http.defaults.baseURL + '/store/search/?factory='+this.factory_id)
                    .then((response)=>{
                        console.log(response);
                        this.products = response.data.results;
                    })
                    .catch((error)=>{
                        console.log("search page error");
                        console.log(error);
                    });
            }
        }
    }
</script>

<style scoped>

</style>
